<template lang="pug">
  div#app
    header-piece
    main
      router-view
    footer-piece
</template>

<script>
export default {
  name: 'App',
  components: {
    HeaderPiece: () => import('@/components/HeaderPiece'),
    FooterPiece: () => import('@/components/FooterPiece')
  }
}
</script>
