import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/views/HomePage')
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('@/views/PrivacyPolicy'),
    meta: { bodyClass: 'second' }
  },
  {
    path: '/terms&conditions',
    name: 'TermsConditions',
    component: () => import('@/views/TermsConditions'),
    meta: { bodyClass: 'second' }
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    }
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.bodyClass) {
    document.body.className = to.meta.bodyClass
  } else {
    document.body.className = ''
  }
  next()
})

export default router
