import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { API } from '@ananasbear/superapi'
import Vuelidate from 'vuelidate'
import './assets/styles/style.sass'
// eslint-disable-next-line

Vue.prototype.$api = new API()
Vue.config.productionTip = false

Vue.use(Vuelidate)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
